import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Container, Stack, TextField } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import BaseProtocl from './components/BaseProtocol';
import UploadFileIcon from '@mui/icons-material/UploadFile';

function App() {
  const [cellValues, setCellValues] = useState<{ [key: string]: string }>({ 'D-1': 'Reservoir', 'C-1': "Reservoir", 'A-3': 'Trash', 'B-3': "Tiprack", 'C-3': "Tiprack" });
  const [protocolReady, setProtocolReady] = useState(false);
  const [protocolString, setProtocolString] = useState('');
  const [protocolName, setProtocolName] = useState('');

  const rowLabels = ['A', 'B', 'C', 'D'];
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    setCellValues(prev => ({
      ...prev,
      [key]: event.target.value,
    }));
  };

  const handleProtocolNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setProtocolName(event.target.value);
  };

  const downloadFile = () => {
    const blob = new Blob([protocolString], { type: 'text/plain' });
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${protocolName}.py`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  };

  const handleSubmit = () => {
    const initialValues = ['D-1', 'A-3', 'B-3', 'C-3'];
    const indexMapping: { [key: string]: number } = {
      'D-1': 1, 'D-2': 2, 'D-3': 3,
      'C-1': 4, 'C-2': 5, 'C-3': 6,
      'B-1': 7, 'B-2': 8, 'B-3': 9,
      'A-1': 10, 'A-2': 11, 'A-3': 12
    };

    const filteredIndices = Object.keys(cellValues).filter(key => !initialValues.includes(key));

    const sortedIndices = filteredIndices.sort((a, b) => {
      const [rowA, colA] = a.split('-');
      const [rowB, colB] = b.split('-');
      if (rowA === rowB) {
        return parseInt(colA) - parseInt(colB);
      }
      return rowB.localeCompare(rowA);
    });

    const validIndices = sortedIndices.filter(key => !isNaN(parseInt(cellValues[key])));
    const sortedValues = validIndices.map(key => parseInt(cellValues[key]));

    const A = validIndices.map(key => indexMapping[key]);
    const B = sortedValues;

    const protocolString = BaseProtocl(A, B, protocolName || "PROTOCOL_NAME");
    setProtocolString(protocolString);
    setProtocolReady(true);
  };

  return (
    <div>
      <AppBar position="static" sx={{ backgroundColor: '#000' }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" component="div">
            OpenTrons Protocol Generator
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <TextField
          label="Protocol Name"
          variant="outlined"
          value={protocolName}
          onChange={handleProtocolNameChange}
          sx={{ mb: 3, width: '100%' }}
        />
        <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '1px', backgroundColor: '#ccc', marginBottom: '20px' }}>
          {rowLabels.map((rowLabel) => (
            <React.Fragment key={`row-${rowLabel}`}>
              {Array.from({ length: 3 }).map((_, colIndex) => {
                const key = `${rowLabel}-${colIndex + 1}`;
                return (
                  <div
                    key={key}
                    style={{
                      backgroundColor: '#fff',
                      padding: '8px',
                      textAlign: 'center',
                      border: '1px solid #ccc'
                    }}
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      value={cellValues[key] || ''}
                      onChange={(e) => handleChange(e, key)}
                      autoComplete="off"
                      disabled={key === 'D-1' || key === 'A-3' || key === 'B-3' || key === 'C-3' || key === 'C-1'}
                    />
                  </div>
                );
              })}
            </React.Fragment>
          ))}
        </div>
        <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            startIcon={<UploadFileIcon />}
            sx={{ backgroundColor: '#000', color: '#fff' }}
          >
            プロトコルを作成する
          </Button>
          <Button
            variant="contained"
            disabled={!protocolReady}
            onClick={downloadFile}
            startIcon={<DownloadIcon />}
            sx={{ backgroundColor: '#000', color: '#fff' }}
          >
            プロトコルをダウンロード(python)
          </Button>
        </Stack>
      </Container>
    </div>
  );
}

export default App;
